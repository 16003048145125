import db from './../../../fb'

const state = { 

      msgSnackbar: false,
      msgSnackbarText:'',
      msgSnackbarColor:'',
      mailRecepients:[],

};
const getters = { 

    getMsgSnackbar(state) {
      console.log(state.msgSnackbar)
      return state.msgSnackbar
    },
    getMsgSnackbarText(state) {
      return state.msgSnackbarText
    },
    getMsgSnackbarColor(state) {
      return state.msgSnackbarColor
    },
    getMailRecepients(state){
        return state.mailRecepients
    }

  };
const mutations = { 

      SET_MSGSNACKBAR(state){
        state.msgSnackbar = !state.msgSnackbar
      },
      SET_MSGSNACKBAR_TEXT(state,val){
        state.msgSnackbarText = val      
      },
      SET_MSGSNACKBAR_COLOR(state,val){
        state.msgSnackbarColor = val
      },
      SET_MAIL_RECEPIENT(state,val){
        state.mailRecepients = val
      } 
      
};
const actions = { 

    showBoolean({commit},{text,color}){
      commit('SET_MSGSNACKBAR')
      commit('SET_MSGSNACKBAR_TEXT',text)
      commit('SET_MSGSNACKBAR_COLOR',color)
      setTimeout(() => {
        commit('SET_MSGSNACKBAR')
      }, 3000);
    },

    async loadMailRecepient({ commit }){
        const response = await db.collection('email-empfaenger').doc('mail-recepients').get();
        const document = response.data()
        const mailRecepients = document.to
      
          commit('SET_MAIL_RECEPIENT', mailRecepients)
    }

};
const namespaced = true;
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced
 }