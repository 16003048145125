import db from './../../../fb'

const state = {
    title: '',
    description: '',

    myServices: []

};

const getters = {
    getTitle(state){
        return state.title
    },
    getDescription(state){
        return state.description
    },
    getMyServices(state){
          return state.myServices
    }
};

const actions = {
  
    loadContent({ dispatch }){

        dispatch('loadTechInfo')
//to laczy oba moduly w jedno wywolanie. Stoji null bo nic innego nie robimy poza wywolaniem;stoji root zeby mial dostep do index.js store
        dispatch('welcome_cms/loadWelcomeInfo',null, {root:true})
        dispatch('getInTouch_cms/loadMailRecepient',null, {root:true})
    
    },  

    async loadTechInfo({ commit }){
        const response = await db.collection('service_page').doc('technologien_info').get();
        const document = response.data()
        const myServices = document.myServices
        const title = document.title
        const description = document.description
//te 3linjiki powyzej to inaczej:
// const response = await db.collection('service_page').doc('technologien_info').get();
//const myServices = response.data().myServices  <-te 1.myServices moze sie dowolnie nazywac
               
          commit('SET_MY_SERVICES',myServices)
          commit('SET_TITLE', title)
          commit('SET_DESCRIPTION', description)
    }
};

const mutations = {
    SET_TITLE(state, val){
        state.title = val
    },
    SET_DESCRIPTION(state, val){
        state.description = val
    },
    SET_MY_SERVICES(state, val){
        state.myServices = val
    }
};
const namespaced = true;

export default {
    state, getters, actions, mutations, namespaced
};