import Vue from 'vue';
import Vuex from 'vuex';
import welcome_cms from './modules/cms/welcomeCMS'
import service_cms from './modules/cms/serviceCMS'
import getInTouch_cms from './modules/cms/getInTouchCMS'
import customerStatementsCMS from './modules/cms/customerStatementsCMS'

Vue.use(Vuex);

export default new Vuex.Store({

    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {
        
    },
    modules: {
        welcome_cms, service_cms, getInTouch_cms, customerStatementsCMS
    }


})