<template>
  <p
    :style="getFontSizeByBreakpoint"
  >
    <slot />
  </p>
</template>

<script>
  export default {
    name: 'BaseText',
    computed: {
      getFontSizeByBreakpoint() {
        let val ="";
        switch (this.$vuetify.breakpoint.name)
        {
          case 'xs':
          val="font-size: 12px !important; font-family: Arial, Helvetica, sans-serif !important; font-weight: 400; font-style: normal;"
          break;
          case 'sm':
          val="font-size: 12px !important; font-family: Arial, Helvetica, sans-serif !important; font-weight: 400; font-style: normal;"
          break;
          case 'md':
          val="font-size: 14px !important; font-family: Arial, Helvetica, sans-serif !important; font-weight: 400; font-style: normal;"
          break;
          case 'lg':
          val="font-size: 16px !important; font-family: Arial, Helvetica, sans-serif !important; font-weight: 400; font-style: normal;"
          break;
          case 'xl':
          val="font-size: 18px !important; font-family: Arial, Helvetica, sans-serif !important; font-weight: 400; font-style: normal;"
          break;
        }
        return val
      }
    },
  }
</script>
