import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import customIcons from './customIcons'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#D3C3BC',
        secondary: '#BFADA5',
        accent: '#D8EBF1',
        info: '#5B5C79',
        slenDblue: '#155b66',
        slenLblue: '#009dce',
        slenLgreen: '#a0ce4e',
        error: '#a0ce4e',
        slenCustomer: '#005f6e',
      },
    },
  },
  icons: {
    values: customIcons
  }
})
