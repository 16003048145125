import XingIcon from './XingIcon'
import VuetifyIcon from './VuetifyIcon'
import VuejsIcon from './VuejsIcon'
import FirebaseIcon from './FirebaseIcon'
import WorkboxIcon from './WorkboxIcon'

export default{
    xing: {
        component: XingIcon
    },
    vuefy: {
        component: VuetifyIcon
    },
    vuejs: {
        component: VuejsIcon
    },
    fire: {
        component: FirebaseIcon
    },
    workbox: {
        component: WorkboxIcon
    }
}