import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyBHuNr-dR2YWs2gqPgCLmWSB8eEx44eo0I",
    authDomain: "contact-us-database-45c74.firebaseapp.com",
    projectId: "contact-us-database-45c74",
    storageBucket: "contact-us-database-45c74.appspot.com",
    messagingSenderId: "1067141568702",
    appId: "1:1067141568702:web:24e2d0cfe98015f599e6ef",
    measurementId: "G-XWK6R86T5D"
  };

  // Initialize Firebase
  const app = firebase.initializeApp(firebaseConfig);

  const db = app.firestore();

  export default db  
  
  db.settings({ timestampsInSnapshots: true});