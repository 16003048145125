import db from '../../../fb'

const state = {
    title: '',
    cus_statements: [],
};

const getters = {
    getTitle(state){
        return state.title
    },
    getCus_statements(state){
          return state.cus_statements
    }
};

const actions = {
  
    loadContent({ dispatch }){
        dispatch('loadCustomerStatements')   
    },  

    async loadCustomerStatements({ commit }){
        const response = await db.collection('customer_page').doc('statements').get();
        const document = response.data()
        const cusStatements = document.cus_statements
        const title = document.title
               
          commit('SET_CUS_STATEMENTS',cusStatements)
          commit('SET_TITLE', title)
    }
};

const mutations = {
    SET_TITLE(state, val){
        state.title = val
    },
    SET_CUS_STATEMENTS(state, val){
        state.cus_statements = val
    }
};
const namespaced = true;

export default {
    state, getters, actions, mutations, namespaced
};