import db from './../../../fb'

const state = {
    title: '',
    description: '',
    manual: [],
};

const getters = {
    getTitle(state){
        return state.title
    },
    getDescription(state){
        return state.description
    },
    getManual(state){
        return state.manual
    }
};

const actions = {
  
    loadWelcomeInfo({dispatch}){
        dispatch('loadWelcomePage')
    },

    async loadWelcomePage({ commit }){
        const response = await db.collection('welcome_page').doc('project_info').get();
        const document = response.data()
        const title = document.title
        const description = document.description
        const manual = document.manual

          commit('SET_TITLE', title)
          commit('SET_DESCRIPTION', description)
          commit('SET_MANUAL', manual)
    }

};

const mutations = {
    SET_TITLE(state, val){
        state.title = val
    },
    SET_DESCRIPTION(state, val){
        state.description = val
    },
    SET_MANUAL(state, val){
        state.manual = val
    }

};
const namespaced = true;

export default {
    state, getters, actions, mutations, namespaced
};