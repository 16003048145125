<template>
<div :style="`height:${this.WrappID_Height}`" class="Wrapping_IFrame_ID"><iframe id="idIframe" :onload="iframeLoaded()" :height="IframeHeight" class="responsive-iframe" src="https://sc-kalender-fa94b.web.app/" ></iframe></div>
    
</template>

<script>
export default {
    name:"BaseIframe",
    data: () => ({
      IframeHeight:"800px",
      WrappID_Height:"800px"
      
    }),
    methods:{
        iframeLoaded() {
            console.log("something")
     //var WrappID = document.getElementById('Wrapping_IFrame_Row');

      

     
      
      //iFrameID.height = "";
     // document.getElementById('idIframe').height = this.IframeHeight+"px"
     // this.WrappID_Height = this.IframeHeight+"px"
        
  }
    },
    mounted(){
    //window.addEventListener("resize", this.iframeLoaded);
    window.addEventListener("message", (event) => {
    
  
   // this.IframeHeight = event.data+"px"
   // this.WrappID_Height = event.data+"px"   
    console.log(event.data)
    this.iframeLoaded()         
  // ...
      }, false);
    }
}
</script>

<style scoped>
.Wrapping_IFrame_ID {
  position: relative;
  width: 100%;
  height: fit-content;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  border: none;
}
</style>