import Vue from 'vue'
import BaseHeading from '@/components/base/BaseHeading'
import BaseText from '@/components/base/BaseText'
import BaseBubble1 from '@/components/base/BaseBubble1'
import BaseBubble2 from '@/components/base/BaseBubble2'
import BaseIframe from '@/components/base/BaseIframe'

Vue.component('BaseHeading', BaseHeading)
Vue.component('BaseText', BaseText)
Vue.component('BaseBubble1', BaseBubble1)
Vue.component('BaseBubble2', BaseBubble2)
Vue.component('BaseIframe', BaseIframe)
